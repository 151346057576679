/**
 * Alert Component
 * An Alert, or notification, informs users of successful or pending actions. Use them sparingly. Don’t show more than one at a time.
 *
 * Kinetic Ref: https://ui.kin.insure/components/alert
 *
 */

import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnInit } from '@angular/core';
import { NgkLoggerError, NgKLoggerService } from '@kin/ngk-logger';
import { PHONE_NUMBERS } from '../../constants/contact-constants';
import { PhonePipe } from '../../pipes/phone-number/phone.pipe';
import '@kin/web-components/dist/external/kin-alert/kin-alert';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [PhonePipe],
  template: `
    <kin-alert open closable variant="danger">
      <b>Uh oh! Something went wrong.</b><br />
      {{ message }} | Contact us at <a [href]="'tel: ' + phoneNumber">{{ phoneNumber | phone }}</a>
    </kin-alert>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AlertComponent implements OnInit {
  logger = inject(NgKLoggerService);
  phoneNumber = PHONE_NUMBERS.sales;

  @Input() message = 'Please try again or call us to speak with an agent.';
  @Input() dataDogMsg: NgkLoggerError = { context: 'Default alert error', priority: 'P4', message: 'unknown error' };
  @Input() disableDataDog = false;

  ngOnInit(): void {
    if (!this.disableDataDog) {
      this.logger.error(this.dataDogMsg);
    }
  }
}
